import * as THREE from 'three'

/**
 * 窗口坐标到世界坐标的转换
 * @param {*} windowX 
 * @param {*} windowY 
 * @param {*} camera 
 * @returns 
 */
export function windowCoordinateTOWorld(windowX,windowY,camera){

    const x=windowX
    const y=windowY
    // console.log("windowX is ",windowX)
    // console.log("windowY is ",windowY)
    // 屏幕坐标转标准设备坐标
    const x1 = ( x / window.innerWidth ) * 2 - 1;
    const y1 = -( y / window.innerHeight ) * 2 + 1;
    //标准设备坐标(z=0.5这个值并没有一个具体的说法)
    const stdVector = new THREE.Vector3(x1, y1, 0.5);
    //然后，再通过 Vector3.unproject(camera) 方法将标准设备坐标转为世界坐标：
    const worldVector = stdVector.unproject(camera);
    return worldVector
}


<template>
    <div id="operation" style="overflow: hidden;height: 100%">

        <div class="items">
            <div class="filename">
                <a-icon type="file-text" />
                <span style="padding: 10px; font-size: 16px; font-weight: bold;">
                    {{ $route.query.name }}
                </span>
            </div>

            <a-card ref="treeList" id="treeList" v-show="showTreeList" title="树木列表" style="height: 500px;
              overflow-y: scroll;
              position: absolute;
              top: 100px ;
              left: 0px">
            </a-card>

            <div class="utils">
                <a-popover placement="bottom">
                    <template #content>
                        <a-button @click="showTreeList = !showTreeList"
                            style="display: block;width: 100%;margin:5px 0;">显示/隐藏包围盒</a-button>
                        <!-- <a-button @click="showOtherPoints"
                            style="display: block;width: 100%;margin:5px 0;">显示/隐藏外部点云</a-button>
                        <a-button @click="rotationModel" style="display: block;width: 100%;margin:5px 0;">旋转模型</a-button> -->
                    </template>
                    <a-button
                        style="height: 100%; border: none; display: flex; justify-content: center; flex-direction: column; align-items: center;">

                        <a-icon type="menu" style="font-size: 18px; margin-top: 2px;" />


                        <span style="font-size: 12px; margin: auto; color: #666; margin-top: 2px;">工具</span>
                    </a-button>
                </a-popover>


                <a-button
                    style="height: 100%; border: none; display: flex; justify-content: center; flex-direction: column; align-items: center;">

                    <router-link to="/soil/map-message">
                        <a-icon type="home" style="font-size: 18px; margin-top: 2px;" />
                    </router-link>

                    <span style="font-size: 12px; margin: auto; color: #666; margin-top: 2px;">主页</span>
                </a-button>

            </div>
        </div>

        <a-spin :spinning="loading" style="position: absolute; left: 50%;top:50%;" v-show="loading">
        </a-spin>

        <!--    threejs展示界面-->
        <div class="container">
            <div ref="three"></div>
        </div>


        <a-card style="top:100px;overflow: scroll;overflow-x: hidden" v-show="oneOrModel" class="info" title="信息">
            <a-card-grid v-for="(value, key, index) in currentTreeInfo" :key="index"
                style="width: 100%; text-align: center;">
                {{ key }}:{{ value }}
            </a-card-grid>
        </a-card>

        <a-card style="top:100px;overflow: scroll;overflow-x: hidden" v-show="!oneOrModel" class="info" title="模型信息">
            <a-card-grid style="width: 100%;height:50px;line-height: 0">
                地区：{{ modelInfo.location }}
            </a-card-grid>
            <a-card-grid style="width: 100%;height:50px;line-height: 0">
                占地面积：{{ modelInfo.area }}m<sup>2</sup>
            </a-card-grid>
            <a-card-grid style="width: 100%;height:50px;line-height: 0">
                物体总数：{{ modelInfo.objNum }}
            </a-card-grid>
            <a-card-grid style="width: 100%;height:60px;line-height: 0">
                <router-link @click="saveModelToLocalStorage" target="_blank"
                    :to="{ path: '/statics' }">点击查看更多</router-link>
            </a-card-grid>
        </a-card>

        <a-drawer :closable="true" :visible="visible" width="800" class="custom-class" style="color: red" title="统计信息"
            placement="right" @close="onClose" @after-visible-change="afterVisibleChange">
            <a-card style="overflow: scroll;overflow-x: hidden" title="模型信息">
                <a-card-grid style="width: 100%;">
                    地区：{{ modelInfo.location }}
                </a-card-grid>
                <a-card-grid style="width: 100%;">
                    占地面积：{{ modelInfo.area }}m<sup>2</sup>
                </a-card-grid>
                <a-card-grid style="width: 100%;">
                    物体总数：{{ modelInfo.objNum }}
                </a-card-grid>
            </a-card>
            <br><br>
            <div ref="linesDom" style="height: 270px;"></div>
        </a-drawer>
        <a-button style="width: 40px; position: absolute;right: 0;bottom: 100px;background-color: greenyellow;"
            @click="showDrawer">
            <a-icon type="double-left"></a-icon>
        </a-button>


    </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { WebGLRenderer } from "three";
import { PLYLoader } from "../../assets/js/threejs";
import axios from "axios";
import { windowCoordinateTOWorld } from "../../assets/js/threejsUtils"

// import * as echarts from "echarts";
// /* eslint-disable */
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import * as echarts from "echarts";

export default {

    data() {
        return {
            loadCnt: 0,
            visible: false,
            pieDomContainer: (null),
            pieDom: (null),
            barChartDomContainer: (null),
            barChartDom: (null),
            linesDomContainer: (null),
            linesDom: (null),
            currentTreeInfo: ({}),//当前树木信息
            three: (null),//three展示div的ref引用
            oneOrModel: (true),//显示树木信息还是模型信息
            loading: (true),//是否正在加载
            treeList: (null),//所有树木的列表
            showTreeList: (false),
            modelInfo: ({
                url: "",
                name: "",
                img: "",
                imgName: "",
                cosName: "",
                objNum: 0,
                area: 0,
                location: ""
            }),
            json: ({}),
            // router: useRouter(),
            // query: (router.currentRoute.value.query),
            colors: [],
            geometryArr: [],
            materialArr: [],
            handlerID: "",
            camera: new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 50),
            renderer: new WebGLRenderer({ antialias: true }),
            scene: new THREE.Scene(),
            controls: null,
            loader: null,
            material: null,
            // 将 boxHelpers 定义为全局变量
            boxHelpers: [],
            coneGroup: new THREE.Group(),
            boxs: [],
            loaded: new Set(),//已经加载的几何体的url
            hoveredObj: [],
            angle: 0,//角度
            angle2: 0,//
            //添加鼠标点击
            raycaster: null,
            pointer: new THREE.Vector2(),
            edge: [0, 0, 0, 0],//边界数组,-x,-y ,x, y
            hoveredObj: [],
        }
    },
    mounted() {

        this.visible = true;//执行一下这个，初始化抽屉，否则第一次点击无法加载echart
        setTimeout(() => {
            this.visible = false;//然后立马关闭
        }, 50)
        //变量声明
        this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 1000);
        this.camera.position.y = -5;
        this.camera.position.z = 5;
        this.camera.position.x = 0;


        this.loader = new PLYLoader();
        this.loader.setCustomPropertyNameMapping({
            customAttribute: ['scalar_intensity'],
        })

        this.material = new THREE.PointsMaterial({
            size: 0.03,
            transparent: true, // 启用透明度
            vertexColors: true,
        });

        this.renderer.setClearColor(0xffffff); //设备背景颜色为白色
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.shadowMap.enabled = true;//启动阴影
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;//选择阴影类型
        this.controls = new OrbitControls(this.camera, this.renderer.domElement)
        //控制器，可以拖动视角
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;




        //获取基本数据
        let formData = new FormData;
        formData.append("name", this.$route.query.name);
        axios.post("http://43.143.170.49:8466/api/model/currentModel", formData, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then(res => {
            this.modelInfo = res.data.data.modelInfo;
            this.json = res.data.data.json
            this.addBoundingBoxes()//读取完成之后，放置包围盒
        })

        this.init();




        this.raycaster = new THREE.Raycaster();
        this.raycaster.layers.set(1); //设置仅操作第一层


        //鼠标移动都某个物体上，变色
        window.addEventListener('pointermove', this.onPointerMove);
        //鼠标点击，选中物体
        window.addEventListener("click", this.onClickObject)




        this.animate();
        //插入dom元素
        this.$refs.three.appendChild(this.renderer.domElement);
    },
    methods: {
        onClose() {
            this.visible = false;
        },

        initLinesChart() {
            var myChart = echarts.init(this.$refs.linesDom);
            var option;

            option = {
                title: {
                    text: '标记点平均高度'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['标记点1', '标记点2', '标记点3', '标记点4',,"",'标记点5','标记点6','标记点7','标记点8','标记点9']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['6月20日', '6月27日', '7月5日', '7月25日']
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '标记点1',
                        type: 'line',
                        data: [10, 20, 45, 35]
                    }, {
                        name: '标记点2',
                        type: 'line',
                        data: [12, 22, 44, 32]
                    }, {
                        name: '标记点3',
                        type: 'line',
                        data: [14, 30, 50, 36]
                    },
                    {
                        name: '标记点4',
                        type: 'line',
                        data: [14, 18, 46, 37]
                    }
                    ,{
                        name: '标记点5',
                        type: 'line',
                        data: [14, 27, 42, 40]
                    }
                    ,{
                        name: '标记点6',
                        type: 'line',
                        data: [14, 30, 50, 33,]
                    },{
                        name: '标记点7',
                        type: 'line',
                        data: [14, 37, 40, 31,]
                    },{
                        name: '标记点8',
                        type: 'line',
                        data: [14, 18, 33, 40,]
                    },{
                        name: '标记点9',
                        type: 'line',
                        data:  [20, 30, 32, 40,]
                    }
                ]
            };

            option && myChart.setOption(option);
        },

        showDrawer() {
            //只有显示以后，才能初始化echart图表
            this.visible = true;
            this.initLinesChart();
        },
        afterVisibleChange() {
            console.log('visible', bool);
        },
        rotateChecked(x, y, z, centerX, centerY, centerZ) {
            console.log(x, y, z, centerX, centerY, centerZ)
            data.rotationParam.x = x
            data.rotationParam.y = y
            data.rotationParam.z = z
            data.rotationParam.centerX = centerX
            data.rotationParam.centerY = centerY
            data.rotationParam.centerZ = centerZ
            data.rotation = true
        },
        onPointerMove(event) {
            // console.log(event.clientX,event.clientY)
            // this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
            // this.pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;

            //需要计算偏移量给出正确的坐标位置
            // console.log(this.renderer.domElement.getBoundingClientRect())
            const DOMRect = this.renderer.domElement.getBoundingClientRect()
            this.pointer.x = ((event.clientX - DOMRect.x) / this.renderer.domElement.clientWidth) * 2 - 1;
            this.pointer.y = - ((event.clientY - DOMRect.y) / this.renderer.domElement.clientHeight) * 2 + 1;
        },
        onClickObject(event) {

            const DOMRect = this.renderer.domElement.getBoundingClientRect()
            // //更新鼠标坐标
            this.pointer.x = ((event.clientX - DOMRect.x) / this.renderer.domElement.clientWidth) * 2 - 1;
            this.pointer.y = - ((event.clientY - DOMRect.y) / this.renderer.domElement.clientHeight) * 2 + 1;

            // 获取物体
            this.raycaster.setFromCamera(this.pointer, this.camera);

            const intersects = this.raycaster.intersectObjects(this.scene.children);
            //操作最近的物体
            console.log("获取物体为", intersects[0])

            if (intersects.length > 0 && intersects[0].object.type !== "Points") {
                if ("tree_info" in intersects[0].object) {//如果存在这个键
                    this.currentTreeInfo = intersects[0].object.tree_info
                }
            }
        },

        saveModelToLocalStorage() {
            localStorage.setItem("modelStatisticName", toRaw(query).name)
        }
        ,

        // 创建包围盒+选项
        /**
         * 读取json，创建立方体，设置滤镜
         * 创建立方体上方的旋转三角锥
         * 
         */
        addBoundingBoxes() {
            // const data = toRaw(json);
            const data = this.json;
            const optionsDiv = document.getElementById("treeList");
            for (const [index, geometry] of data.geometry.entries()) {
                // 创建包围盒
                const { x, y, z } = geometry.dimension;
                const [centerX, centerY, centerZ] = geometry.center;


                const coneGeometry = new THREE.ConeGeometry(0.4, 1, 3);
                const coneMaterial = new THREE.MeshPhysicalMaterial({ color: 0x00ff00 });
                const cone = new THREE.Mesh(coneGeometry, coneMaterial);
                cone.originalColor = 0x00ff00
                cone.position.set(centerX, centerY, centerZ * 2 + 0.5)
                cone.rotation.x -= Math.PI / 2
                cone.layers.enable(1);
                this.coneGroup.add(cone)
                // console.log(coneGroup)


                // 创建包围盒的 Helper 对象

                const cubegeometry = new THREE.BoxGeometry(x, y, z);
                const material = new THREE.MeshBasicMaterial({
                    color: 0x00ff00,
                    depthWrite: false, // 不遮挡后面的模型
                    transparent: true, // 设置为true，opacity才会生效
                    opacity: 0.5,
                });
                const cube = new THREE.Mesh(cubegeometry, material);
                // cube.position.set(centerX, centerY, centerZ)
                cube.position.set(centerX, centerY, 0)

                cube.visible = false
                cube.originalColor = 0x00ff00
                cube.layers.enable(1)
                cube.tree_info = geometry,//手动给cube添加属性，包括当前树的所有信息
                    this.scene.add(cube);
                const box = new THREE.BoxHelper(cube, 0xff0000);
                box.visible = false
                this.scene.add(box);

                this.boxs.push({
                    cube,
                    box,
                })


                // 添加复选框
                const option = document.createElement('input');
                option.type = 'checkbox';
                option.checked = false;
                option.id = geometry.tag;
                option.addEventListener('change', () => {
                    cube.visible = option.checked;
                    box.visible = option.checked

                    cube.userData.interactive = option.checked; // 设置交互性
                    if (!option.checked) {//未选中，则恢复
                        console.log("取消选中多选框，恢复颜色")
                    }
                });

                const runOption = document.createElement("input")
                runOption.type = 'radio'
                runOption.checked = ""
                runOption.checked = "checkedTree"
                runOption.value = geometry.center.value
                runOption.name = "rotateTree"
                runOption.addEventListener("change", (event) => {
                    //直接绑定对应的xyz旋转

                    rotateChecked(x, y, z, centerX, centerY, centerZ)

                })


                const checklabel = document.createElement('checklabel');
                checklabel.htmlFor = option.id;
                checklabel.textContent = geometry.tag

                optionsDiv.appendChild(option);
                optionsDiv.appendChild(checklabel);
                optionsDiv.appendChild(runOption)
                optionsDiv.appendChild(document.createElement('br'));
            }
            this.scene.add(this.coneGroup);
        },




        //数据加载进度
        onProgress(xhr) {
            if ((xhr.loaded / xhr.total * 100) == 100) {
                this.loading = false;
            }
        },
        loadModel(url) {
            const _this = this;
            this.loader.load(url, function (geometry) {
                _this.loadCnt++;
                console.log(_this.loadCnt)
                _this.geometryArr.push(geometry)
                _this.materialArr.push(_this.material)

                const numVertices = geometry.attributes.position.count;
                const colors = new Float32Array(numVertices * 3);
                /**
                 * 强度控制点云颜色深浅
                 * 渐变固定为蓝色到绿色，其中绿色在地下，蓝色在头上
                 * 未来方案：根据不同类型的物体自动分配颜色
                 *
                 * 在 CSS 中，可以使用色相、饱和度和明度（HSL）来指定颜色，格式如下：
                 * hsla (hue, saturation, lightness) 色相（ hue ）是色轮上从 0 到 360 的度数。 0 是红色，120 是绿色，240 是蓝色。
                 * 饱和度（ saturation ）是一个百分比值，0％ 表示灰色阴影，而 100％ 是全色。
                 * 亮度（lightness）也是百分比，0％ 是黑色，50％ 是既不明也不暗，100％是白色。
                 * 调色
                 * https://www.w3school.com.cn/css/css_colors_hsl.asp#:~:text=%E5%9C%A8%20CSS%20%E4%B8%AD%EF%BC%8C%E5%8F%AF%E4%BB%A5%E4%BD%BF%E7%94%A8%E8%89%B2%E7%9B%B8%E3%80%81%E9%A5%B1%E5%92%8C%E5%BA%A6%E5%92%8C%E6%98%8E%E5%BA%A6%EF%BC%88HSL%EF%BC%89%E6%9D%A5%E6%8C%87%E5%AE%9A%E9%A2%9C%E8%89%B2%EF%BC%8C%E6%A0%BC%E5%BC%8F%E5%A6%82%E4%B8%8B%EF%BC%9A%20hsla%20%28hue%2C%20saturation%2C%20lightness%29%20%E8%89%B2%E7%9B%B8%EF%BC%88,%E6%98%AF%E7%BB%BF%E8%89%B2%EF%BC%8C240%20%E6%98%AF%E8%93%9D%E8%89%B2%E3%80%82%20%E9%A5%B1%E5%92%8C%E5%BA%A6%EF%BC%88%20saturation%20%EF%BC%89%E6%98%AF%E4%B8%80%E4%B8%AA%E7%99%BE%E5%88%86%E6%AF%94%E5%80%BC%EF%BC%8C0%EF%BC%85%20%E8%A1%A8%E7%A4%BA%E7%81%B0%E8%89%B2%E9%98%B4%E5%BD%B1%EF%BC%8C%E8%80%8C%20100%EF%BC%85%20%E6%98%AF%E5%85%A8%E8%89%B2%E3%80%82
                 */
                for (let i = 0; i < numVertices * 3; i += 3) {
                    //渐变长度,两个不同颜色之间的过度距离
                    const len = 0.5;
                    //点云强度,强度比率用来控制hsl颜色里面的亮度信息L
                    const rate = (geometry.attributes.customAttribute.array[i / 3] + 100) / 300;
                    //高度比率，用来控制hsl颜色里面的色相信息H
                    const intensity = (geometry.attributes.position.array[i + 2] - 0) / (len - 0); // 计算点云强度，范围为0到1
                    //HSL颜色
                    const color = new THREE.Color().setHSL((0.6 - (intensity * 0.4)), 0.6, 0.5); // 将颜色设置为蓝色到树绿色之间的渐变
                    color.toArray(colors, i);
                }
                geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

                const singleMergeMesh = new THREE.Points(geometry, _this.material);
                singleMergeMesh.castShadow = true;
                singleMergeMesh.position.setY(0.4);
                _this.scene.add(singleMergeMesh); // 在场景中添加合并后的mesh(模型)
            }, this.onProgress);
        },


        init() {
            // 添加环境光
            const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
            this.scene.add(ambientLight);

            // 添加点光源
            const pointLight = new THREE.PointLight(0xffffff, 0.5);
            pointLight.position.set(10, 10, 10);
            pointLight.castShadow = true; //产生阴影
            pointLight.shadow.camera.visible = true; //可见性为真
            pointLight.shadow.mapSize.width = 1024; //阴影贴图宽度
            pointLight.shadow.mapSize.height = 1024; //阴影贴图高度
            pointLight.shadow.camera.near = 0.1; //阴影相机近裁剪面
            pointLight.shadow.camera.far = 100; //阴影相机远裁剪面
            this.scene.add(pointLight);


        },

        //开始渲染动画，向外暴露，调用时开启动画,记录请求动画帧的id
        animate() {

            //鼠标指针
            // update the picking ray with the camera and pointer position
            this.raycaster.setFromCamera(this.pointer, this.camera);
            // calculate objects intersecting the picking ray
            const intersects = this.raycaster.intersectObjects(this.scene.children);
            if (intersects.length > 0 && intersects[0].object.type !== "Points") {
                intersects[0].object.material.color.set(0xff0000);
                // console.log("obj is:",intersects[0].object)
                for (let i = 0; i < this.hoveredObj.length; i++) {//还原其他
                    if (this.hoveredObj[i].object == intersects[0].object) {//如果指向自己，就保持
                        continue;
                    }
                    //没有指向自己，就还原
                    this.hoveredObj[i].object.material.color.set(this.hoveredObj[i].object.originalColor)
                }

                this.hoveredObj.push(intersects[0]);//增加新的变化
            }

            //三角动画
            for (let i = 0; i < this.coneGroup.children.length; i++) {
                this.coneGroup.children[i].rotation.y += Math.PI / 180
            }
            // console.log("渲染一帧")
            //实时监控窗口所处的世界坐标
            const x = window.innerWidth
            const y = window.innerHeight
            const worldVector0 = windowCoordinateTOWorld(0, 0, this.camera)//左上角坐标
            const worldVector1 = windowCoordinateTOWorld(x, y, this.camera)//右下角坐标
            //获取范围
            const minX = Math.min(worldVector0.getComponent(0), worldVector1.getComponent(0))
            const minY = Math.min(worldVector0.getComponent(1), worldVector1.getComponent(1))
            const maxX = Math.max(worldVector0.getComponent(0), worldVector1.getComponent(0))
            const maxY = Math.max(worldVector0.getComponent(1), worldVector1.getComponent(1))
            // console.log("世界坐标为：",worldVector0,worldVector1)
            // console.log("坐标范围是:",minX,minY,maxX,maxY)
            //最好前端维护一个边界数组

            //如果超过边界了
            //从维护的hash值返回解析真实坐标
            const unhash_x = this.edge[0] * 5;
            const unhash_y = this.edge[1] * 5;
            const unhash_x1 = this.edge[2] * 5;
            const unhash_y1 = this.edge[3] * 5

            //如果当前维护的范围比窗口所展示的范围小，那么需要扩展，继续请求点云模型
            let flag = false;//是否已经被跟新的标记
            let nx = unhash_x;
            let ny = unhash_y;
            let px = unhash_x1;
            let py = unhash_y1;
            if (unhash_x > minX) {//已加载的左侧边界比窗口显示的左侧边界靠右，即左侧仍有空位置，那么就hash后的
                nx = minX;
                flag = true;
            }
            if (unhash_y > minY) {
                ny = minY
                flag = true;
            }
            if (unhash_x1 < maxX) {
                px = maxX
                flag = true;
            }
            if (unhash_y1 < maxY) {
                py = maxY
                flag = true;
            }
            if (flag) {//如果被更新过，那么就加载并把新的边界传入,这里只管传入，是否返回，要看后端
                //从这里更新edge边界，以后端的hash为准

                let updateFormData = new FormData;
                updateFormData.append("modelName", this.$route.query.name);
                updateFormData.append("positiveX", px);
                updateFormData.append("negativeX", nx);
                updateFormData.append("positiveY", py);
                updateFormData.append("negativeY", ny);
                // console.log("px,py,nx,ny", px, py, nx, ny)

                //模拟更新edge,这里需要模拟，如果等待axios返回，会因为异步操作发送多个请求
                this.edge[0] -= 1;
                this.edge[1] -= 1;
                this.edge[2] += 1;
                this.edge[3] += 1;

                // 向后端发送请求即可，需要去重
                // 无法使用异步，操作set，list进行去重操作
                axios.post("http://43.143.170.49:8466/api/model/updateMap", updateFormData, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                }).then(res => {

                    for (let i = 0; i < res.data.data.length; i++) {

                        //获取到的res有hash过后的x，y，根据这些信息，更新
                        // console.log(res.data.data[i])
                        this.edge[0] = Math.min(this.edge[0], res.data.data[i].x);
                        this.edge[1] = Math.min(this.edge[1], res.data.data[i].y);
                        this.edge[2] = Math.max(this.edge[2], res.data.data[i].x);
                        this.edge[3] = Math.max(this.edge[3], res.data.data[i].y);
                        //如果没有加载这个点云
                        if (!this.loaded.has(res.data.data[i].url)) {
                            this.loaded.add(res.data.data[i].url)
                            // console.log(loaded)
                            this.loadModel(res.data.data[i].url);
                            // console.log(scene)
                        } else {
                            continue
                        }
                    }
                    // console.log('geometries is ', geometryArr);
                    // console.log('json is ', json);
                })

                flag = false;//重置标记，防止多次访问

            }

            this.handlerID = requestAnimationFrame(this.animate);
            const vFOV = (this.camera.fov * Math.PI) / 180;
            const height = 2 * Math.tan(vFOV / 2) * Math.abs(this.camera.position.z);
            const width = height * this.camera.aspect;

            this.renderer.render(this.scene, this.camera);
            this.controls.update();
        },
        // 结束请求动画
        stopAnimate() {
            console.log("清理场景物体...")
            cancelAnimationFrame(this.handlerID);
            // this.renderer.forceContextLoss();
            // this.renderer.dispose();
            this.flows = [];
            this.scene = null;
            this.camera = null;
            this.controls = null;
            // this.renderer.domElement = null;
            // this.renderer = null;
            console.log("停止渲染");
        }


    },

    beforeDestroy() {
        this.stopAnimate()
    }

}

</script>


<style scoped>
#operation {
    width: 100%;
    height: 100%;
}

.container {
    width: 100%;
    height: 100%;
    background-color: #f6f8f9;
    z-index: 0;
}

.items {
    width: 85vw;
    height: 80px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.filename {
    /* width: 100px; */
    height: 60px;
    padding-top: 16px;
    padding-left: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 6%);
    border-radius: 6px;
}

.utils {
    /* width: 100px; */
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 10px;
}

.others {
    /* width: 100px; */
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 10px;
}

.info {
    height: 38%;
    width: 280px;
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 6%);
    position: absolute;
    right: 20px;
}

#components-popover-demo-placement .ant-btn {
    width: 70px;
    text-align: center;
    padding: 0;
    margin-right: 8px;
    margin-bottom: 8px;
}

.ant-btn-icon-only {
    height: 100%;
    width: auto;
    padding: 0px 5px;
}

.ant-btn {
    border: none;
    /* border-radius: 0; */
}

.statisticBtn {
    margin: 5px 0;
    border: 2px #1890ff solid;
    position: absolute;
    right: 20px;
    bottom: 20px;
    height: 95px;
    width: 40px;
    writing-mode: vertical-rl;
    text-align: center;
    padding-right: 9px;
    vertical-align: center;
}

.chartContainer {
    position: fixed;
    background-color: #f0f2f5;
    padding-top: 20px;
    padding-left: 20px;
    border-radius: 10px;
    right: -450px;
    bottom: 20px;
    width: 450px;
    height: 300px
}
</style>